import * as React from 'react'
import { graphql, Link } from 'gatsby'
import Hero from '../components/Hero'
import logo from '../images/logo_coloq.svg'
import { GatsbySeo } from 'gatsby-plugin-next-seo';

export const query = graphql`
  query {
    hero: file(relativePath: { eq: "tower_hero.jpg" }) {
      childImageSharp {
        fluid(maxWidth:1600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default ({data}) => {

  return (
  <>
<GatsbySeo title='PanSurg ColoQ'
      description='An international cross-sectional survey of colorectal cancer management during the COVID-19 pandemic'/>
  <Hero title="PanSurg ColoQ"
        subtitle="An international cross-sectional survey of colorectal cancer management during the COVID-19 pandemic"
        fluid={data.hero.childImageSharp.fluid}
         buttonTxt = "Participate"
       buttonLink= "https://forms.gle/kPZU9UJNhgcasbnV8"
       breadcrumbs
        secondcrumb = 'Research'
        secondlink = '/research'
        activecrumb = 'ColoQ'
  />

  <section className="section is-small" style={{marginTop:'2rem'}}>
    <div className="container">
      <div className="is-thin">

      <div className="has-text-centered">
        <img src={logo} width="400px" alt="coloq Logo" style={{marginBottom:'2rem'}}/>
      </div>

      <div className="content">
        <p>PanSurg ColoQ is an international cross-sectional survey of current practice
        in the management of colorectal cancer. The first draft of data from 109 respondants
        accross 34 countries is available to <strong>interact with in our data viewer below.</strong>
        </p>

        <p> If you're a colorectal attending or consultant or regularly perform elective colorectal
        cancer resections in your practice we want to hear how COVID-19 has affected your management.
        </p>
      </div>

      <div className="buttons is-centered">
        <a className="button is-rounded is-red" href="https://forms.gle/kPZU9UJNhgcasbnV8" target="_blank">Complete the survey</a>
      </div>

      </div>
    </div>
  </section>

  <section className="section is-small">
    <div className="container">
    <p className="title has-text-darkBlue">ColoQ Data Viewer</p>
      <div className="box">
        <iframe src="https://pansurg.shinyapps.io/colorectal_survey/" width="100%" height="2000px"></iframe>
      </div>

    </div>
  </section>

  </>
  )
}
